<!--  -->
<template>
  <div class='daodu'>
    <div class="header">
      <img src="../assets/img/daodu/dingbu.png" alt="">
      <div class="title">
        <span>专家授课</span>
        <span>模拟备考</span>
        <span>高清视频</span>
        <span>贴心服务</span>
      </div>
      <div class="logo">
        <img src="../assets/img/logo.jpg" alt="">
        <div>
          <div>湘源医药食品职业培训学校 </div>
          <span>Xiangyuan Pharmaceutical and Food Vocational Training School</span>
        </div>
      </div>
    </div>

    <div class="history">
      <h3>广东省执业药师继续教育政策变化</h3>
      <div>
        <div>
          <div>1</div>
          <p>主管单位由协会改变为广东省药监局，培训平台由协会网站改为广东省执业药师管理系统。所有药师都先到此管理系统注册再开启继续教育学习。</p>
        </div>
        <div>
          <div>2</div>
          <p>培训单位不再是唯一选择，广东省执业药师管理系统上的施教机构都可以选择，学分都得到注册部门的认可。</p>
        </div>
        <div>
          <div>3</div>
          <p>学习、考核和管理更加规范，增加了人脸识别和抓拍功能，禁止代学代考。培训平台与省局管理系统实时传送数据，随时监管。</p>
        </div>
        <div>
          <div>4</div>
          <p>学员选择增多，可以根据省局管理系统或进入各培训机构查询课程体系、师资、感受服务水平，选择自己合适的培训机构。</p>
        </div>
        <div>
          <div>5</div>
          <p>补学课时都为60学时，同样可以任意选择施教机构完成学习。</p>
        </div>
      </div>
    </div>

    <div class="flow">
      <h3>继续教育专业科目学习流程</h3>
      <div>
        <div>
          <div class="xuhao" style="border-color: #205ABF;color: #205ABF">01</div>
          <div class="line" style="background-color: #205ABF"></div>
          <span>广东执业药师管理系统报名</span>
        </div>
        <div>
          <div class="xuhao" style="border-color: #4CC08C;color: #4CC08C">02</div>
          <div class="line" style="background-color: #4CC08C"></div>
          <span>选择湘源医药食品学校</span>
        </div>
        <!-- <div>
          <div class="xuhao" style="border-color: #006CE4;color: #006CE4">03</div>
          <div class="line" style="background-color: #006CE4"></div>
          <span>上传委托书</span>
        </div> -->
        <div>
          <div class="xuhao" style="border-color: #00BEAF;color: #00BEAF">04</div>
          <div class="line" style="background-color: #00BEAF"></div>
          <span>选择课程</span>
        </div>
        <div>
          <div class="xuhao" style="border-color: #FF8C21;color: #FF8C21">05</div>
          <div class="line" style="background-color: #FF8C21"></div>
          <span>确认缴费</span>
        </div>
        <div>
          <div class="xuhao" style="border-color: #F9BC15;color: #F9BC15">06</div>
          <div class="line" style="background-color: #F9BC15"></div>
          <span>学习考试</span>
        </div>
        <div>
          <div class="xuhao" style="border-color: #4CC08B;color: #4CC08B">07</div>
          <div class="line" style="background-color: #4CC08B"></div>
          <span>学分打印</span>
        </div>
      </div>
    </div>

    <div class="teacher" v-if="teacherArr.length > 0">
      <h3>精选名师专业教学  好继续教育从选择好老师开始</h3>
      <div class="msg">
        <div class="head" v-if="!show">
          <img :src="teacherArr[teacherIdx].img" alt="">
          <div>
            <span>免费试听</span>
            <div @click="show = true">
              <div></div>
            </div>
          </div>
        </div>
        <video v-else :src="teacherArr[teacherIdx].video" ref="video" autoplay controls></video>
        <div class="content">
          <div class="name">
            <span>{{teacherArr[teacherIdx].name}}</span>
            <div>{{teacherArr[teacherIdx].title}}</div>
          </div>
          <div class="detail">{{teacherArr[teacherIdx].content}}</div>
          <div class="task">主讲课题：{{teacherArr[teacherIdx].kecheng}}</div>
        </div>
      </div>
      <div class="arr">
        <div :class="teacherIdx == index && 'checked'" v-for="(item, index) in teacherArr" :key="index" @click="show = false, teacherIdx = index">
          <div class="head">
            <img :src="item.img" alt="">
          </div>
          <div class="name">{{item.name}}</div>
        </div>
      </div>
    </div>

    <div class="tixi">
      <h3>选择您所需要的广东省执业药师继续教育课程体系</h3>
      <div class="table">
        <div class="thead">
          <div class="xuhao"></div>
          <div class="name">课程名称</div>
          <div class="user">主讲人</div>
          <div class="title">职称</div>
        </div>
        <div class="tlist">
          <div class="tbody" v-for="(item, index) in tixiArr" :key="index">
            <div class="xuhao">{{index+1}}</div>
            <div class="name">{{item.mingcheng}}</div>
            <div class="user">{{item.zjr}}</div>
            <div class="title">{{item.zhiwu}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <img src="../assets/img/daodu/dibu.png" alt="">
      <div>
        <h3>选择我们的六大理由</h3>
        <div class="reason">
          <div>
            <span>授课专家</span>
            <div>甄选专业师资授课 继续教育经验丰富</div>
          </div>
          <div>
            <span>教研团队</span>
            <div>科学模块化分工 自主研发专业课程体系</div>
          </div>
          <div>
            <span>授课方法</span>
            <div>多方位教学模式 随堂练习备考更灵活</div>
          </div>
          <div>
            <span>培训经验</span>
            <div>数年继续教育经验 专业教学服务指导</div>
          </div>
          <div>
            <span>课件视频</span>
            <div>高清视频课程 课堂场景内容丰富</div>
          </div>
          <div>
            <span>遴选机构</span>
            <div>广东省药监局遴选继续教育施教机构</div>
          </div>
        </div>
        <div class="service">
          <div class="hint">
            <div>加入我们，继教启程</div>
            <div>让药师学习更方便！</div>
            <div>24小时服务热线：400-811-5865转2</div>
          </div>
          <div class="code">
            <img src="../assets/img/code/xiaoqi.png" alt="">
            <div>在线微信客服1</div>
          </div>
          <div class="code">
            <img src="../assets/img/code/zhongqi.png" alt="">
            <div>在线微信客服2</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '../api/home'

export default {
  components: {},
  data() {
    return {
      teacherArr: [],
      teacherIdx: 0,
      tixiArr: [],
      show: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTeacherArr()
    this.getTixi()
  },
  mounted() {
    
  },
  //方法集合
  methods: {
    getTixi () {
      Api.getTixi().then(res => {
        if (res.data.code == 1) {
          this.tixiArr = res.data.data
        } else {
          this.$message.error("课程体系获取失败")
        }
      })
    },
    getTeacherArr () {
      Api.getTeacherArr({
        type: 1
      }).then(res => {
        if (res.data.code == 1) {
          this.teacherArr = res.data.data
        } else {
          this.$message.error("名师列表获取失败")
        }
      })
    },
  },
}
</script>

<style lang='less' scoped>
.daodu {
  background-color: #FAFAFA;
  > div  {
    > h3 {
      text-align: center;
    }
    > div {
      width: 70%;
      margin: 0 auto;
    }
  }

  .header {
    position: relative;
    > .title {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 120px;
      height: 60px;
      border-radius: 50px;
      width: 40%;
      background: linear-gradient(114deg, #FFE7BE 0%, #FEF7E5 100%);
      span {
        color: #A93D07;
        font-size: 26px;
        font-weight: bold;
      }
    }
    .logo {
      position: absolute;
      top: 30px;
      left: 15%;
      display: flex;
      align-items: center;
      > div {
        color: #ffffff;
        margin-left: 10px;
        div {
          margin-bottom: 5px;
          font-size: 18px;
          text-align-last: justify;
          text-align: justify;
          text-justify: distribute-all-lines; 
        }
        span {
          font-size: 10px;
        }
      }
      img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .history {
    background-color: #FAFAFA;
    margin: 50px 0;
    > div {
      margin: 80px auto;
      display: flex;
      justify-content: space-between;
      align-content: center;
      > div {
        background-color: #E7F7ED;
        position: relative;
        padding: 50px 15px 15px;
        width: 17%;
        > div {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          background: linear-gradient(180deg, #62C15A 0%, #4CC08B 100%);
          width: 60px;
          height: 60px;
          line-height: 60px;
          border-radius: 50%;
          font-family: Serif;
          font-size: 24px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }

  .flow {
    background-color: #F4F8F9;
    padding: 50px 0;
    > div {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 150px;
        text-align: center;
        .xuhao {
          width: 40px;
          height: 40px;
          line-height: 40px;
          border-radius: 50%;
          text-align: center;
          border: 1px solid #000;
        }
        .line {
          background-color: #000;
          width: 120px;
          height: 13px;
          margin: 5px auto 15px;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: -20px;
            border-width: 20px 20px;
            border-style: solid;
            border-color: #F4F8F9 transparent transparent transparent;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            right: -20px;
            border-width: 20px 20px;
            border-style: solid;
            border-color: transparent transparent  #F4F8F9 transparent;
          }
        }
      }
    }
  }

  .teacher {
    background-color: #ffffff;
    padding: 50px 0 30px;
    .msg {
      margin: 50px auto;
      display: flex;
      .head {
        width: 40%;
        height: 280px;
        border-radius: 10px;
        background-color: #6ED5AC;
        display: flex;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        img {
          width: 180px;
          height: 240px;
        }
        > div {
          width: 220px;
          height: 50px;
          border-radius: 25px;
          padding: 0 15px;
          background-color: #DCFFF0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #016B4D;
            font-size: 22px;
            margin-left: 20px;
          }
          > div {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #016B4D;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            > div {
              border: 12px solid #016B4D;
              border-top-width: 8px;
              border-bottom-width: 8px;
              border-left-color: #ffffff;
              margin-left: 14px;
            }
          }
        }
      }
      video {
        width: 40%;
        height: 280px;
        background-color: #000;
      }
      .content {
        flex: 1;
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > div {
          display: flex;
          justify-content: space-between;
          span {
            font-size: 20px;
          }
        }
        .detail {
          white-space: pre-wrap;
          word-break: break-all;
          flex: 1;
          margin: 20px 0;
        }
      }
    }
    .arr {
      width: 70%;
      padding: 20px 0;
      overflow-y: visible;
      overflow-x: auto;
      display: flex;
      > div {
        margin-left: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        > .head {
          width: 100px;
          height: 100px;
          position: relative;
          border-radius: 50%;
          box-sizing: content-box;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .name {
          box-sizing: content-box;
          margin: 20px 0 0;
          padding: 2px 5px;
        }
      }
      > div:last-child {
        margin-right: 50px;
      }
      .checked {
        .head {
          border: 1px solid #4CC08C;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 50%;
            transform: translate(8px, -100%);
            border-width: 20px 8px;
            border-style: solid;
            border-color: transparent transparent #4CC08C transparent;
          }
        }
        .name {
          border: 1px solid #4CC08C;
          color: #4CC08B;
        }
      }
    }
  }

  .tixi {
    margin: 50px 0;
    .table {
      margin-top: 30px;
      .tlist {
        max-height: 540px;
        overflow-y: auto;
      }
      .tbody,
      .thead {
        display: flex;
        > div {
          padding: 15px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .xuhao {
          width: 6%;
        }
        .name {
          width: 33%;
        }
        .user {
          width: 20%;
        }
        .title {
          width: 40%;
        }
      }
      .thead {
        background-color: #06B487;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        color: #ffffff;
        margin-bottom: 1px;
        div {
          font-size: 20px;
        }
      }
      .tbody {
        > div {
          border-bottom: 1px solid #D8D8D8;
        }
        > .xuhao {
          border: 0;
          background-color: #4B4F62;
          color: #ffffff;
        }
        .name, .user {
          border-right: 1px solid #D8D8D8;
        }
      }
    }
  }

  .footer {
    position: relative;
    img {
      width: 100%;
      height: 700px;
    }
    > div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      > div {
        width: 70%;
      }
      h3 {
        text-align: center;
      }
      .reason {
        display: flex;
        justify-content: space-between;
        > div {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          border: 1px solid #4CC08D;
          box-shadow: 0px 2px 4px 0px #4CC08D;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          div {
            width: 75%;
            margin-top: 20px;
            text-align: center;
          }
          span {
            font-size: 20px;
            color: #4CC08C;
          }
        }
      }
      .service {
        display: flex;
        justify-content: space-around;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 50px 0;
        .hint {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 35%;
          div {
            font-size: 26px;
          }
        }
        .code {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            margin-bottom: 10px;
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}
</style>